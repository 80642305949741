import React from 'react';
import { useState,useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Button,Row,Container,Col,Form,Modal,OverlayTrigger,Tooltip} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import {Sendreq} from '../req'

export default function (props){
    const {Hotspot}=props;
    const [WidthPercent,SetWidthPercent]=useState(0);
    const [HeightPercent,SetHeightPercent]=useState(0);
    const [TopPercent,SetTopPercent]=useState(0);
    const [LeftPercent,SetLeftPercent]=useState(0);
    useEffect(()=>{
        console.log(Hotspot);
        
        



    },[])
    function Clickpluse(){
        //alert("clicked!");
        window.open(Hotspot.HotSpotLink, '_blank').focus();
    }
    
   
   
const Style={
    width:Hotspot.WidthPercent+"%",
    height:Hotspot.HeightPercent+"%",
    top:Hotspot.TopPercent+"%",
    left:Hotspot.LeftPercent+"%"
}
    return(
        <>
            
            <div style={Style} className="HotSpotAddisional" >

<OverlayTrigger
key={"top"}
placement={"top"}
overlay={
<Tooltip id={`tooltip-${Hotspot.id}`}>
<strong >{Hotspot.HotSpotDescription}</strong>
</Tooltip>
}
>
 <img onClick={Clickpluse} className="plusSVG img-fluid" src="pluse.svg" />
</OverlayTrigger>
    



</div>
                      

        </>
    

    );





}