import React from 'react';
import { useState,useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Button,Row,Container,Col,Form,Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import {Sendreq} from '../req';
import HotSpotLeft1 from './HotSpotLeft';
import HotSpotRight1 from './HotSpotRight';

export default function (props){
    const {object,CatalogHotSpots}=props;
    const [BelongHotSpots,SetBelongHotSpots]=useState([]);
    const [HotSpotLeft,SetHotSpotLeft]=useState([]);
    const [HotSpotRight,SetHotSpotRight]=useState([]);
    const [Moblie,SetMoblie]=useState(false);

    function ReturnLeft(HotSpot){

        if(parseFloat(HotSpot.LeftPercent)<100 && HotSpot.BelongCube===object.id){
            return true;
        }

        return false;




    }

    function ReturnRight(HotSpot){

        if(parseFloat(HotSpot.LeftPercent)>100 && HotSpot.BelongCube===object.id){
            return true;
        }

        return false;
        
    }

    function StringToNumbers(){

    }
    console.log(object);

    

    useEffect(()=>{

        var width=$(window).width();
        if(width<=600){
            SetMoblie(true);

        }


        SetHotSpotLeft(
            CatalogHotSpots.filter((x)=>ReturnLeft(x))


        )


        SetHotSpotRight(
            CatalogHotSpots.filter((x)=>ReturnRight(x))
          );


    




    },[])



   if(Moblie===false){
       
    return(
        <>
    
       
        
      

    
            
                        <div class='item'>
                        {HotSpotLeft.map((item,index)=>

                <HotSpotLeft1 Hotspot={item} />



)}
                            <img src={'https://'+object.ImageLeft}></img>
                    </div>
                    <div class='item'>
                    {HotSpotRight.map((item,index)=>

                        <HotSpotRight1 Hotspot={item} />



)}
                    <img src={'https://'+object.ImageRight}></img>

                    </div>
 

                    </>

    );



   }
   else{
       
    return(
        <>
    
       
        
      

    
            
      
                    <div class='item'>
                    {HotSpotRight.map((item,index)=>

                        <HotSpotRight1 Hotspot={item} />



)}
                    <img src={'https://'+object.ImageRight}></img>

                    </div>

                    <div class='item'>
                        {HotSpotLeft.map((item,index)=>

                <HotSpotLeft1 Hotspot={item} />



)}
                            <img src={'https://'+object.ImageLeft}></img>
                    </div>
 

                    </>

    );

   }





}