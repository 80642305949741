import React from "react";
import { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import $ from "jquery";
import { Sendreq } from "../req";
import Cube from "./CubeDisaplay";

function Catalog(props) {
  const [CurrectPage, SetCurrectPage] = useState(0);
  const [CatalogData, SetCatalogData] = useState([]);
  const [CatalogCubes, SetCatalogCubes] = useState([]);
  const [CatalogHotSpots, SetCatalogHotSpots] = useState([]);
  const [DataObject, SetDataObject] = useState([]);
  const [Loading, SetLoading] = useState(true);
  const [DisaplayDrag, SetDisaplayDrag] = useState(false);
  var url_string = window.location.href; //
  var url = new URL(url_string);
  var c = url.searchParams.get("catalogid");
  var d = url.searchParams.get("page");

  console.log(c);
  console.log(d);

  function counter(event) {
    if (!event.namespace) {
      return;
    }
    var slides = event.relatedTarget;
    var num = slides.relative(slides.current() + 2);
    var pagenumber = num / 2 + 1;
    // console.log(pagenumber);
    ///var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?catalogid=1&page='+pagenumber;
    ///window.history.pushState({ path: newurl }, '', newurl);
    //alert()
  }

  function MoveTriger() {
    ///$('.owl-dot:nth(2)').trigger('click');
    //-1 first page
    //2
    /// setTimeout(function(){document.getElementsByClassName("owl-dots")[0].getElementsByTagName("button")[1].click();}, 500);
    // $('.owl-dot:nth(2)').trigger('click');
  }
  useEffect(() => {
    var width = $(window).width();
    var height = $(window).width();

    //window.resizeTo(width-1000, height-1000);

    if (width <= 600) {
      SetDisaplayDrag(true);
    }
  }, []);

  useEffect(() => {
    MoveTriger();
  }, [CurrectPage]);
  function NextBtn() {
    /// SetCurrectPage(CurrectPage+1);
    // alert("Click");
    var owl = $(".owl-carousel");
    owl.owlCarousel();
    owl.trigger("next.owl.carousel");
  }
  function previous() {
    SetCurrectPage(CurrectPage - 1);
  }
  useEffect(async () => {
    /*  var width=$(window).width();
        var Platform="Desktop";
        if(width<=600){
            Platform="Moblie";

        } */

    var Object2 = await Sendreq(
      "https://extal-api.wdev.co.il/api/Client/Catalog/GetCatalog",
      { CatalogID: c }
    );
    if (Object2 !== false || Object2 !== null) {
      await SetCatalogHotSpots(Object2["HotSpots"]);
      await SetCatalogCubes(Object2["Cubes"]);
      await SetCatalogData(Object2["CatalogData"]);
      SetLoading(false);
      // CatalogName
      //console.log(Object2['CatalogData'][0]['CatalogName'])
      document.title =
        "קטלוג אקסטל - " + Object2["CatalogData"][0]["CatalogName"];
    }
  }, []);

  if (Loading) {
    return (
      <>
        <h1>טוען...</h1>
      </>
    );
  } else {
    if (CatalogData[0].Visible === "1") {
      return (
        <div className="cat">
          <Container>
            <OwlCarousel
              className="owl-theme"
              rtl={false}
              stagePadding={0}
              margin={0}
              nav
              slideBy={2}
              loop={false}
              items={2}
              touchDrag={DisaplayDrag}
              mouseDrag={DisaplayDrag}
              navText={[
                '<div class="NextAsid"><img src="next2.svg"></img></div>',
                '<div  class="previous"><img src="previous2.svg"></img></div>',
              ]}
              onChanged={counter}
              responsive={{
                0: {
                  items: 1,
                },
                600: {
                  items: 1,
                },
                605: {
                  items: 2,
                },
              }}
            >
              {CatalogCubes &&
                CatalogCubes.map((item, index) => (
                  <>
                    {item.ImageLeft && (
                      <Cube
                        object={item}
                        key={index}
                        CatalogHotSpots={CatalogHotSpots}
                      />
                    )}
                  </>
                ))}
            </OwlCarousel>
          </Container>
        </div>
      );
    } else {
      return (
        <>
          <h1>הקטלוג אינו זמין להצגה כרגע</h1>
        </>
      );
    }
  }
}

export default Catalog;
