import "./App.css";
import Catalog from "./components/Catalog";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  withOrientationChange,
} from "react-device-detect";
import Swip from "./components/Swip";
function App(props) {
  const { isPortrait } = props;

  return (
    <>
      <div className="App">
        <header className="App-header">
          <img alt="מוצצים לוגו" className="MobileLogo" src="logo.png"></img>
        </header>
        <div
          style={{ display: "grid", width: "100%", placeItems: "center" }}
        ></div>
        <MobileView MobileView>
          {isPortrait && (
            <div
              style={{ display: "grid", width: "100%", placeItems: "center" }}
            >
              <br />
              <br />
              <Swip />
            </div>
          )}
        </MobileView>
        <MobileView MobileView>
          {isPortrait && (
            <>
              <br />
              <br />
            </>
          )}
        </MobileView>
        <Catalog />

        <div style={{ width: "10vh", margin: "0 auto" }}>
          <a
            className=""
            href="https://www.waveproject.co.il"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="wave project TLD logo"
              className="MoblieWaveLogo"
              src="small_logo.svg"
            ></img>
          </a>
        </div>

        <MobileView MobileView>
          {isPortrait && (
            <div style={{ width: "10vh", margin: "0 auto" }}>
              <a
                href="https://www.waveproject.co.il"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="wave project TLD logo"
                  className="MoblieWaveLogo"
                  src="small_logo.svg"
                ></img>
              </a>
            </div>
          )}
        </MobileView>
      </div>
    </>
  );
}

export default withOrientationChange(App);
