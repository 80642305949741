
import react from 'react';
import axios from 'axios'



///With token
export async function Sendreq(Url,dataArray){
    let data;
   

       return new Promise((resolve,reject)=>{
            var bodyFormData = new FormData()
          //  bodyFormData.append("token", JSON.stringify(dataArray))
           bodyFormData.append("data", JSON.stringify(dataArray))
            axios({
                method: 'post',
                url: Url,////"https://bengurion.wdev.co.il/api/admin/management/returnclosingdays",
                data: bodyFormData
            })
            .then(function (response) {

                if(response.data.status==true)
                {
                    

                    resolve(response.data.data);
                }
                else{
                    //console.log("po"+response.data.data)
                    alert(response.data.message);
                    resolve (false);
                }


            }).catch((error) => {
                alert("בקשה נכשלה");
                resolve(false);

        })



          });




    
}








export default function req()
{



return(
    <>
    </>
);


}