import React from "react";
import orientation from "./orientation.svg";
function Swip() {
  return (
    <div className="swipGrid">
      <img className="swipImg " src={orientation} alt="rotate screen" />
      <div className="flach">
        <p
          style={{
            color: "white",
            margin: "0",
            fontWeight: "500",
            fontSize: "0.8rem",
          }}
        >
          לחוויה מרבית סובבו את המכשיר
        </p>
      </div>
    </div>
  );
}

export default Swip;
